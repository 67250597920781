import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexE9M0F0TuqEMeta } from "/vercel/path0/pages/auth0/callback/index.vue?macro=true";
import { default as index98lWnPghHgMeta } from "/vercel/path0/pages/auth0/logout/index.vue?macro=true";
import { default as _91id_93oCuKlYLwwoMeta } from "/vercel/path0/pages/doctor/[id].vue?macro=true";
import { default as index1TH53ech1KMeta } from "/vercel/path0/pages/doctor/index.vue?macro=true";
import { default as _91id_93dlIB2OB7E8Meta } from "/vercel/path0/pages/email-templates/[id].vue?macro=true";
import { default as add_45newpR0hgoyXjpMeta } from "/vercel/path0/pages/email-templates/add-new.vue?macro=true";
import { default as indexjGknB9GrN1Meta } from "/vercel/path0/pages/email-templates/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as _91id_93eAcTDECsnwMeta } from "/vercel/path0/pages/payee/[id].vue?macro=true";
import { default as index3ASPGM05jmMeta } from "/vercel/path0/pages/payee/index.vue?macro=true";
import { default as _91id_93n9xpVhSWgyMeta } from "/vercel/path0/pages/rebate-hoi/[id].vue?macro=true";
import { default as indexvc4jdCqi5fMeta } from "/vercel/path0/pages/rebate-hoi/index.vue?macro=true";
import { default as _91id_93Ivho12jcRmMeta } from "/vercel/path0/pages/rebate/[id].vue?macro=true";
import { default as indexThaVx51mPTMeta } from "/vercel/path0/pages/rebate/index.vue?macro=true";
import { default as _91month_93zIBYC0HXkNMeta } from "/vercel/path0/pages/report/[month].vue?macro=true";
import { default as indexAzUOXnxcxeMeta } from "/vercel/path0/pages/report/index.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/vercel/path0/pages/404.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/vercel/path0/pages/admin/index.vue")
  },
  {
    name: "auth0-callback",
    path: "/auth0/callback",
    component: () => import("/vercel/path0/pages/auth0/callback/index.vue")
  },
  {
    name: "auth0-logout",
    path: "/auth0/logout",
    component: () => import("/vercel/path0/pages/auth0/logout/index.vue")
  },
  {
    name: "doctor-id",
    path: "/doctor/:id()",
    component: () => import("/vercel/path0/pages/doctor/[id].vue")
  },
  {
    name: "doctor",
    path: "/doctor",
    component: () => import("/vercel/path0/pages/doctor/index.vue")
  },
  {
    name: "email-templates-id",
    path: "/email-templates/:id()",
    component: () => import("/vercel/path0/pages/email-templates/[id].vue")
  },
  {
    name: "email-templates-add-new",
    path: "/email-templates/add-new",
    component: () => import("/vercel/path0/pages/email-templates/add-new.vue")
  },
  {
    name: "email-templates",
    path: "/email-templates",
    component: () => import("/vercel/path0/pages/email-templates/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "payee-id",
    path: "/payee/:id()",
    component: () => import("/vercel/path0/pages/payee/[id].vue")
  },
  {
    name: "payee",
    path: "/payee",
    component: () => import("/vercel/path0/pages/payee/index.vue")
  },
  {
    name: "rebate-hoi-id",
    path: "/rebate-hoi/:id()",
    component: () => import("/vercel/path0/pages/rebate-hoi/[id].vue")
  },
  {
    name: "rebate-hoi",
    path: "/rebate-hoi",
    component: () => import("/vercel/path0/pages/rebate-hoi/index.vue")
  },
  {
    name: "rebate-id",
    path: "/rebate/:id()",
    component: () => import("/vercel/path0/pages/rebate/[id].vue")
  },
  {
    name: "rebate",
    path: "/rebate",
    component: () => import("/vercel/path0/pages/rebate/index.vue")
  },
  {
    name: "report-month",
    path: "/report/:month()",
    component: () => import("/vercel/path0/pages/report/[month].vue")
  },
  {
    name: "report",
    path: "/report",
    component: () => import("/vercel/path0/pages/report/index.vue")
  }
]